<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แสดงข้อมูลงบประมาณ</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fiscalyear"
                                rules="required"
                            >
                              <v-select
                                  :items="fiscalyearItems"
                                  v-model="fiscalyear"
                                  :error-messages="errors"
                                  dense
                                  outlined
                                  @change="changeYear()"
                              ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>โครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fa"
                                rules="required"
                            >
                              <v-autocomplete
                                v-model="fa"
                                :items="faItems"
                                :filter="customFilter"
                                :error-messages="errors"
                                item-value="fa"
                                dense
                                outlined
                              >
                                <template v-slot:selection="{ item }">
                                  <span>{{ item.fa }} {{ item.fa_text }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.fa }} {{ item.fa_text }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>

                              </v-autocomplete>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                          <v-btn depressed color="primary" @click="display()" class="mr-10">
                                แสดงข้อมูล
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="my-6" />
                    <v-row>
                      <v-col>
                          <v-card outlined>
                            <v-data-table
                                :headers="headers"  
                                :items="budgetData"
                                disable-sort
                                hide-default-footer
                            >
                              <template v-slot:item.budget="{ item }">
                                {{ displayNumberFormat(item.budget) }}
                              </template>
                              <template v-slot:item.spend="{ item }">
                                {{ displayNumberFormat(item.spend) }}
                              </template>
                              <template v-slot:item.remain="{ item }">
                                <span class="font-weight-bold">{{ displayNumberFormat(parseFloat(item.budget) - parseFloat(item.spend)) }}</span>
                              </template>
                            </v-data-table>
                          </v-card>
                      </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";
// import { read, utils } from 'xlsx';
import numeral from 'numeral'

export default {
    data: () => ({
        storeUser: null,
        uploadDate: new Date().toISOString().substr(0, 10),
        formChkErrors: false,
        fiscalyear: null,
        fiscalyearItems: [],
        fileExcel: [],
        budgetData: [],
        fa: null,
        faItems: [],
        headers: [
          {
              text: "fund center text",
              value: "fundcenter_text"
          },
          {
              text: "FA",
              value: "fa"
          },
          {
              text: "FA text",
              value: "fa_text"
          },
          {
              text: "FP",
              value: "fp"
          },
          {
              text: "FP Text",
              value: "fp_text"
          },
          {
              text: "CI",
              value: "ci"
          },
          {
              text: "CI Text",
              value: "ci_text"
          },
          {
              text: "งบประมาณ",
              value: "budget"
          },
          {
              text: "ใช้ไป",
              value: "spend"
          },
          {
              text: "คงเหลือ",
              value: "remain"
          }
        ]
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      var startYear = new Date().getFullYear() + 5
      var endYear = 2000
      for (let index = startYear; index >= endYear; index--) {
        this.fiscalyearItems.push(index)
      }
      this.$store.commit('setLoading', false)
    },
    methods: {
      displayNumberFormat(num){
        return numeral(num).format('0,0.00')
      },
      async display() {
        this.$store.commit('setLoading', true)
        try {
          const req = {
            params: {
              fiscal_year: this.fiscalyear,
              fa: this.fa
            }
          }

          const res = (await Api().get("getbudget", req)).data;

          if (res.status == 'success') {
              this.budgetData = res.data
          } else {
              console.log(res.data);
          }
        } catch (err) {
            console.log(err.message);
        }
        this.$store.commit('setLoading', false)
      },
      async changeYear() {
        this.$store.commit('setLoading', true)
        this.fa = null
        this.getFA()
        this.budgetData = []
        this.$store.commit('setLoading', false)
      },
      customFilter (item, queryText, itemText) {
        console.log(itemText)
        const textOne = item.fa.toLowerCase()
        const textTwo = item.fa_text.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      async getFA(){
        try {
          const req = {
            params: {
              fiscal_year: this.fiscalyear
            }
          }

          const res = (await Api().get("fa", req)).data;

          if (res.status == 'success') {
              this.faItems = res.data
          } else {
              console.log(res.data);
          }
        } catch (err) {
            console.log(err.message);
        }
      }
    }
};
</script>